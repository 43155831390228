$(function () {
    if ($('input[name=affid]').val()) {
        $('#trial_form').attr('action', $('#trial_form').attr('action') + '?affid=' + $('input[name=affid]').val())
    }

    initData();

    if ($('.hurry-countdown').length) {
        $('.hurry-countdown').countdown({
            date : Date.now() + 1000 * 60 * 7 - 1000,
            render : function (date) {
                if (date.sec == 60) {
                    sec = 0;
                    min = date.min + 1;
                } else {
                    sec = date.sec;
                    min = date.min;
                }

                this.el.innerHTML = this.leadingZeros(min) + ':' +
                    this.leadingZeros(sec) + '.' +
                    this.leadingZeros(parseInt(date.millisec / 10));
            },
            refresh : 467
        });
    }

});

$('.rush-my-order').click(function () {
    $('html, body').animate({scrollTop : $('#form-container').offset().top - 40},
    500);
    return false;
});

$('[data-toggle="modal"]').on("click", function (e) {
    var link = $(this);
    $("#myModal").find(".modal-title").text(link.text());
    $("#myModal").find(".modal-body").html('').load(link.attr("data-link"));
});