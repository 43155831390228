$(function () {
    $('.better-than-botox-bottom-claim').css("margin-top", claimbuttonHeight);
});

$('.claim-bottle').click(function () {
    if (!form_validator()) {
        $('html, body').animate({scrollTop: $('#opt_in_form').offset().top - 40}, 500);
    }
    return false;
});
