var AJAX_URL = '/wp-admin/admin-ajax.php';
//var productsPrice = [];
var couponsAmount = [
];
var shippingsAmount = [
];
var productId = 0;

var limelight_messages = new Array();
limelight_messages["invalid_email"] = "Invalid E-mail Address";              //caller webForms.js - isValidEmail()
limelight_messages["invalid_no_fields_entered"] = "Please Fill in all fields";           //caller inline - form_validator()
limelight_messages["invalid_terms"] = "Please Agree with the Terms";         //caller inline - form_validator()
limelight_messages["invalid_phone"] = "Please enter ten-digit number as your phone"; //caller inline - form_validator()

/*
 * AlertHandler allows you to either use a standard alert to show errors or customize to some kind of HTML version of your own
 */
function AlertHandler(message)
{
    showMsgBox(message);
}

/*
 * ConfirmHandler allows you to either use a standard winbdow.confirm to show errors or customize to some kind of HTML version of your own. Must return boolean
 */
function ConfirmHandler(message)
{
    return window.confirm(message);
}


function validate_fields()
{
    update_expire();
    result = false;
    if (document.getElementById('hasFormSubmitted').value != '') {
        var prevPost = new Date(document.getElementById('hasFormSubmitted').value);
        prevPost.setSeconds(prevPost.getSeconds() + 3);
        var currentTime = new Date();
        if (prevPost < currentTime) {
            // -- blank out the formsubmitted because user went back and forth and hidden values still cached
            document.getElementById('hasFormSubmitted').value = '';
        }
    }

    var ccReq = false;
    var empty_fields = new Array();
    var empty_field_num = new Array();
    var empty_count = 0;
    var str = 'field indicated Cant be empty:';
    for (i = 0; i < required_fields.length; i++) {
        var field_ref = document.getElementById(required_fields[i]);

        if (required_fields[i] == 'cc_number') {
            ccReq = true;
        }

        if ((required_fields[i] == 'cc_number' || required_fields[i] == 'cc_expires' || required_fields[i] == 'cc_cvv') &&
            (document.getElementById('cc_type').value == 'checking' ||
                document.getElementById('cc_type').value == 'eft_germany' ||
                document.getElementById('cc_type').value == 'offline' ||
                document.getElementById('cc_type').value == 'boleto' ||
                document.getElementById('cc_type').value == 'giro' ||
                document.getElementById('cc_type').value == 'paypal'
                )) {
            ccReq = false;
            continue;
        }


        if (required_fields[i] == 'fields_state2') {
            var funcName = 'SetCountryValue2';
            if (typeof funcName == 'string' && eval('typeof ' + funcName) == 'function')
            {
                eval(funcName + '()');
            }
        }

        //catch all spaces, this is invalid
        var tempStr = $.trim(field_ref.value);

        if ((required_fields[i].indexOf('fields_state') != -1) && (tempStr.toLowerCase() == 'select state')) {
            tempStr = '';
        }

        if ((tempStr == '') || (tempStr == ' ')) {
            var label = required_fields_label[i];
            if (label == 'State')
            {
                label = $('#fields_state_region').text().replace(':', '');
            }
            if (label.length) {
                empty_fields[empty_count] = label;
                empty_field_num[empty_count] = i;
                empty_count++;
            }
        }

        if ((field_ref.length == 0) || (field_ref.value == null) || (field_ref.value == '')) {
            empty_fields[empty_count] = required_fields_label[i];
            empty_field_num[empty_count] = i;
            empty_count++;
        }
    }

    if (empty_fields.length) {
        for (x in empty_fields) {
            str = str + '<br/>' + empty_fields[x];
            if (empty_fields[x] != undefined) {
                AlertHandler('Please Enter Your ' + empty_fields[x] + '');
                //document.getElementById(required_fields[empty_field_num[x]]).focus();
            } else {
                AlertHandler(GetTranslation('invalid_no_fields_entered'));
            }
            return;
        }
    }

    if ($('input[id^=fields_phone]').filter(':visible').length) {
        if ((!phonePreValidByValue($('#fields_phone').val()))) {
            AlertHandler(GetTranslation('invalid_phone'));
            return;
        }
    }
    var tm_check = document.getElementById('terms');
    if (tm_check && tm_check.checked == false) {
        AlertHandler(GetTranslation('invalid_terms'));
        return;
    }

    if (empty_count != 0) {
        var diverr = document.getElementById('err');
        diverr.innerHTML = '';
        diverr.innerHTML = str;
    } else {
        if ($('#fields_email').is(':visible') == true) {
            var emailCheck = isValidEmail(document.forms['opt_in_form'].fields_email.value);
        } else if ($('input[id^=fields_email]').filter(':visible').length) {
            var emailCheck = isValidEmail($('input[id^=fields_email]').filter(':visible').first().val());
        } else {
            var emailCheck = true;
        }
        if (emailCheck) {
            if (document.getElementById('hasFormSubmitted').value == '' && loaded) {
                result = true;
            }
        }
    }
    return result;
}

function form_validator()
{
    if (validate_fields()) {
        SubmitForm();
    }
}

function SubmitForm()
{
    blockPage(($('#blockMessage').length) ? $('#blockMessage') : null);
    timeout = setTimeout(function () {
        for (i = 0; i < required_fields.length; i++) {
            if (($('#' + required_fields[i]).length) && (required_fields != 'cc_number') && (required_fields != 'cc_cvv')) {
                setCookie(required_fields[i], $('#' + required_fields[i]).val(), null, '/');
            }
        }

        var d = new Date();

        document.getElementById('hasFormSubmitted').value = d.toUTCString();

        step = '';
        if ($('#step').length) {
            step = $('#step').val();
        }

        $.ajax({
            url : handlerUrl,
            type : "POST",
            data : $("#opt_in_form").serialize(),
            dataType : "json"
        }).done(function (data) {
            unblockPage();

            if ((typeof data.currentOrderId !== 'undefined') && ($('input[name="currentOrderId"]').length)) {
                $('input[name="currentOrderId"]').val(data.currentOrderId);
            }

            if (data.responseCode == 101) { //3ds redirect
                threeDRedirect(data.orderId);
            } else if (data.errorFound > 0) {
                showMsgBox(data.response_text);
                return false;
            } else {
                if (step == 'first') {
                    $('#prospect_id').val(data.prospectId);
                } else {
                    $('#order_id').val(data.orderId);
                }
                if (typeof checkoutBeforeSubmitForm == 'function')
                    checkoutBeforeSubmitForm();
                document.next_form.submit();
            }
        }).fail(function () {
            unblockPage();
            showMsgBox('The server is unavailable, please retry within a minute');
            return false;
        });
    }, 100);
}

function submit_upsell(upsellConfirmed)
{
    blockPage(($('#blockMessage').length) ? $('#blockMessage') : null);
    $('#upsellConfirmed').val(upsellConfirmed);
    timeout = setTimeout(function () {
        $.ajax({
            url : handlerUrl,
            type : "POST",
            data : $("#opt_in_form").serialize(),
            dataType : "json"
        }).done(
            function (data) {
                unblockPage();
                if ((typeof data.currentOrderId !== 'undefined') && ($('input[name="currentOrderId"]').length)) {
                    $('input[name="currentOrderId"]').val(data.currentOrderId);
                }
                if (data.errorFound > 0) {
                    showMsgBox(data.response_text);
                    return false;
                } else {
                    $('input[name="order_id"]').val(data.orderId);
                    document.next_form.submit();
                }
            }).fail(function () {
            unblockPage();
            showMsgBox('Error');
            return false;
        });
    }, 100);
}

function threeDRedirect(orderId) {
    window.location.href = handlerUrl + ((handlerUrl.indexOf('?') == -1) ? '?' : '&') + 'method=three_d_redirect&orderId=' + orderId;
}

/**
 * show message box
 */
function showMsgBox(text)
{
    if ($('#msgBox').length == 0) {
        $('body').append('<div id="msgBox" class="modal fade" role="dialog"><div class="modal-dialog"><div class="modal-content"><div class="modal-header"><button type="button" class="close" data-dismiss="modal">&times;</button><h4 class="modal-title">Message</h4></div><div class="modal-body"></div><div class="modal-footer"><button type="button" class="btn btn-default" data-dismiss="modal">Close</button></div></div></div></div>');
    }

    if (text != null) {
        $('#msgBox .modal-body').html(htmlspecialchars_decode(text));
    }

    //adjustModalMaxHeightAndPosition('#msgBox');

    $('#msgBox').modal('show');
}

/**
 * show declined cart message box
 */
function showCardDeclinedMsgBox()
{
    $('#msgBox .modal-body').load('/cardError.html', function () {
        showMsgBox(null);
    });
}

/**
 * initialize shipping data
 */
function fillShippingData()
{
    if ((getURLParam('mode') != 'test') && (typeof landingUrl !== 'undefined') && ($('#prospect_id').length) && (!$('#prospect_id').val())) {
        if (typeof checkoutBeforeRedirect == 'function')
            checkoutBeforeRedirect();
    	window.location.href = landingUrl;
    }

    $.each(required_fields, function (i, field) {
        if (($('#' + field).length) && ($('#' + field).length) && (getCookie(field))) {
            $('#' + field).val(getCookie(field));
            if (field == 'fields_country') {
                updateState('fields_country', 'fields_state');
            } else if (field == 'billing_fields_country') {
                updateState('billing_fields_country', 'billing_fields_state');
            }
        }
    });

    loaded = true;
}

/**
 * function gets URL parameter form the query string
 * @param strParamName string parameter name 
 * @return string unescaped parameter value
 */
function getURLParam(strParamName)
{
    strReturn = "";
    strHref = window.location.href;
    if (strHref.indexOf("?") > -1) {
        strQueryString = strHref.substr(strHref.indexOf("?"));
        aQueryString = strQueryString.split("&");
        for (iParam = 0; iParam < aQueryString.length; iParam++) {
            if (aQueryString[iParam].toLowerCase().indexOf(strParamName.toLowerCase() + "=") > -1) {
                aParam = aQueryString[iParam].split("=");
                strReturn = aParam[1];
                break;
            }
        }
    }
    return unescape(strReturn);
}


/**
 * loadCoupon
 */
function loadCoupon(showMessage)
{
    if ($('#coupon').length) {
        sCoupon = '';
        sCoupon = $('#coupon').val();
        if (!sCoupon)
            sCoupon = getURLParam('code');
        if (!sCoupon)
            sCoupon = getCookie('lastCouponCode');
        if ((sCoupon) && (sCoupon.length > 0)) {
            $('#coupon').val(sCoupon);
            applyCouponToCart(sCoupon, showMessage);
        }
    }
}


function updateState(country, state)
{
    $.post(
        AJAX_URL, {
            method : 'getStates',
            country : $('#' + country).val()
        },
    function (data) {
        $('#' + state).html(data);
    }
    );
}

function update_expire()
{
    var month = document.getElementById("fields_expmonth");
    var year = document.getElementById("fields_expyear");
    if (month && year) {
        var month_value = month.options[month.selectedIndex].value;
        var year_value = year.options[year.selectedIndex].value;
        var cc_expires = '';
        if ((month_value != '') && (year_value != '')) {
            cc_expires = month_value + year_value;
        }
        $('input[name="cc_expires"]').val(cc_expires);
    }
}

function GetTranslation(id, defaultText)
{
    var ret = '';
    if (typeof (limelight_messages) != 'undefined' && limelight_messages.hasOwnProperty(id)) {
        ret = limelight_messages[id];
    } else {
        ret = defaultText;
    }
    return ret;
}


function isValidEmail(str)
{
    var at = "@";
    var dot = ".";
    var lat = str.indexOf(at);
    var lldot = str.lastIndexOf(dot);
    var lstr = str.length;
    var ldot = str.indexOf(dot);
    var valid = true;

    if (str.indexOf(at) == -1) {
        valid = false;
    }

    if (str.indexOf(at) == -1 || str.indexOf(at) == 0 || str.indexOf(at) == (lstr - 1) || str.indexOf(at) == (lstr - 3)) {
        valid = false;
    }

    if (lldot == lstr - 1 || lldot == lstr - 2 || (lldot == ldot && lldot < lat)) {
        valid = false;
    }

    if (str.indexOf(dot) == -1 || str.indexOf(dot) == 0 || str.indexOf(dot) == (lstr - 1)) {
        valid = false;
    }

    if (str.indexOf(at, (lat + 1)) != -1) {
        valid = false;
    }

    if (str.substring(lat - 1, lat) == dot || str.substring(lat + 1, lat + 2) == dot) {
        valid = false;
    }

    if (str.indexOf(dot, (lat + 2)) == -1) {
        valid = false;
    }

    if (str.indexOf(" ") != -1) {
        valid = false;
    }

    var isDoubleByte = false;
    for (var i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) > 127) {
            isDoubleByte = true;
            break;
        }
    }

    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (!isDoubleByte && reg.test(str) == false) {
        valid = false;
    }

    if (!valid) {
        showMsgBox(GetTranslation('invalid_email', 'Invalid Email Address'));
    }

    return valid;
}

function htmlspecialchars_decode(string, quote_style) {
    var optTemp = 0,
        i = 0,
        noquotes = false;
    if (typeof quote_style === 'undefined') {
        quote_style = 2;
    }
    string = string.toString()
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>');
    var OPTS = {
        'ENT_NOQUOTES' : 0,
        'ENT_HTML_QUOTE_SINGLE' : 1,
        'ENT_HTML_QUOTE_DOUBLE' : 2,
        'ENT_COMPAT' : 2,
        'ENT_QUOTES' : 3,
        'ENT_IGNORE' : 4
    };
    if (quote_style === 0) {
        noquotes = true;
    }
    if (typeof quote_style !== 'number') {
        // Allow for a single string or an array of string flags
        quote_style = [
        ].concat(quote_style);
        for (i = 0; i < quote_style.length; i++) {
            // Resolve string input to bitwise e.g. 'PATHINFO_EXTENSION' becomes 4
            if (OPTS[quote_style[i]] === 0) {
                noquotes = true;
            } else if (OPTS[quote_style[i]]) {
                optTemp = optTemp | OPTS[quote_style[i]];
            }
        }
        quote_style = optTemp;
    }
    if (quote_style & OPTS.ENT_HTML_QUOTE_SINGLE) {
        string = string.replace(/&#0*39;/g, "'"); // PHP doesn't currently escape if more than one 0, but it should
        // string = string.replace(/&apos;|&#x0*27;/g, "'"); // This would also be useful here, but not a part of PHP
    }
    if (!noquotes) {
        string = string.replace(/&quot;/g, '"');
    }
    // Put this in last place to avoid escape being double-decoded
    string = string.replace(/&amp;/g, '&');

    return string;
}



function onlyNumbers(e, type)
{
    var keynum;
    var keychar;
    var numcheck;
    if (window.event) {// IE
        keynum = e.keyCode;
    } else if (e.which) {// Netscape/Firefox/Opera
        keynum = e.which;
    }
    keychar = String.fromCharCode(keynum);
    numcheck = /\d/;

    switch (keynum) {
        case 8:    //backspace
        case 9:    //tab
        case 35:   //end
        case 36:   //home
        case 37:   //left arrow
        case 38:   //right arrow
        case 39:   //insert
        case 45:   //delete
        case 46:   //0
        case 48:   //1
        case 49:   //2
        case 50:   //3
        case 51:   //4
        case 52:   //5
        case 54:   //6
        case 55:   //7
        case 56:   //8
        case 57:   //9
        case 96:   //0
        case 97:   //1
        case 98:   //2
        case 99:   //3
        case 100:  //4
        case 101:  //5
        case 102:  //6
        case 103:  //7
        case 104:  //8
        case 105:  //9
            result2 = true;
            break;
        case 109: // dash -
            if (type == 'phone') {
                result2 = true;
            } else {
                result2 = false;
            }
            break;
        default:
            result2 = numcheck.test(keychar);
            break;
    }

    return result2;
}

/**
 * validation of phone format length
 * @param phoneIdentifier string value of phone field 
 * @return boolean flag of valid phone number
 */
function phonePreValidByValue(value)
{
    if (value.length < 10) {
        return false;
    } else {
        return true;
    }
}

/** 
 * custom page block function
 */
function blockPage(msg)
{
    if ( (typeof msg == 'undefined') || (msg == null)) {
        msg = null;
        css = {};
    } else {
        height = $(window).height();
        width = $(document).width();
        css = {
            'left' : width/2 - (msg.width() / 2),
            'top' : height/2 - (msg.height() / 2),
            'width': msg.width()
        }
    }
    if ($.blockUI) {
        $.blockUI({
            message : msg,
            css: css,
            fadeIn : 0,
            fadeOut : 0
        });
    }
}

/** 
 * custom page unblock function
 */
function unblockPage()
{
    if ($.unblockUI) {
        $.unblockUI();
    }
}

function updatePrice(prodId) {
	if (prodId > 0) {
	    productId = prodId;
	
	    if (typeof (couponsAmount[productId]) == 'undefined') {
	        couponsAmount[productId] = 0;
	    }
	
	    if ((couponsAmount[productId] == 0)) {
	        loadCoupon(false);
	    }
	
	    if ($('#product_chk_' + productId))
	        $('#product_chk_' + productId).prop('checked', true);
	
	    if ($('#product_id').length) {
	        $('#product_id').val(productId);
	    }
	
	    if ($('.pprice').length) {
	        $('.pprice').text('$' + (productsPrice[productId].toFixed(2) - couponsAmount[productId].toFixed(2)));
	    }
	}
    updateShippingCost();
}

function updateShippingCost() {
    if ((productId == 13) || (productId == 11)) {
        shippingId = ($('#fields_country').val() == 'US') ? 5 : 7;
    } else {
        shippingId = ($('#fields_country').val() == 'US') ? 1 : 3;
    }

    $('#shipping_id').val(shippingId);

    if (typeof (shippingsAmount[shippingId]) != 'undefined') {
        shippingCost = shippingsAmount[shippingId];
        $('#shipping_cost').val(shippingCost);
        $('.shipping_cost_text').text('$' + shippingCost);
    } else {
		shippingCost = 0;
    }
    
    totalCost = shippingCost.valueOf() + (productId > 0 ? productsPrice[productId].valueOf() : 0) - couponsAmount[productId].valueOf();

    if ($('.total_text').length) {
        $('.total_text').text('$' + totalCost.toFixed(2));
    }

}
/**
 * apply coupon to the cart
 * @param discount string discount code
 */
function applyCouponToCart(coupon, showMessage)
{
    blockPage();

    setCookie('lastCouponCode', coupon, null, '/');
    $('#coupon').val(coupon);
    $('#coupon_m').val(coupon);
    $('.discount-text, .discount-row-mob').addClass('hidden');

    couponsAmount[productId] = 0;

    if (coupon.length > 0) {
        $.ajax({
            type : 'POST',
            url : AJAX_URL,
            data : {
                method : 'checkCoupon',
                campaign_id : $('#campaign_id').val(),
                product_id : productId,
                product_price : productId > 0 ? productsPrice[productId].toFixed(2) : 0,
                promo_code : coupon
            },
            success : function (response) {
                if (response['status'] > 0) {
                    couponsAmount[productId] = parseFloat(response['couponAmount']);
                    $('#promo_code').val(coupon);
                    $('.discount').text('-$' + parseFloat(response['couponAmount']).toFixed(2));
                    $('.discount-text, .discount-row-mob').removeClass('hidden');
                    updatePrice(productId);
                } else {
                    for (var i in couponsAmount) {
                        couponsAmount[i] = 0;
                    }
                    $('#promo_code').val('');
                    if ((typeof (showMessage) == 'undefined') || showMessage) {
                        showMsgBox(response['content']);
                    }
                }
            },
            complete : function (data) {
                unblockPage();
            },
            dataType : 'json'
        });
    } else {
        $('#promo_code').val('');
        for (var i in couponsAmount) {
            couponsAmount[i] = 0;
        }
        updatePrice(productId);
        unblockPage();
    }
}
/**
 * update shipping related totals
 **/
function updateTotals()
{
    subtotal = cart.subtotal - cart.subtotalDiscount;
    $('#pprice').html('$' + subtotal.toFixed(2));

    //show cart totals for not empty cart
    if (cart.items.length > 0) {
        $("fieldset#ppricebox").css('display', 'block');
        $("fieldset#totamtbox").css('display', 'block');
    }

    //console.log(cart.items.length);
    if (cart.subtotalDiscount != 0) {
        $('#discount').text('-$' + cart.subtotalDiscount);
        $('#discount').show();
        $('#discount-label').show();
    } else {
        $('#discount').text('');
        $('#discount').hide();
        $('#discount-label').hide();
    }

    if (($('select#shipping_method').length) && ($('#spricebox').length)) {
        $('#spricebox').show();
    }


    $('#handlingPrice').html('$' + cart.shippingHandling);
    $('#totamt').html('$' + (cart.total + cart.shippingHandlingDiscount).toFixed(2));
}

/** 
 * setup quantity of product to be purchased
 * @param quantity integer product quantity to be set
 */
function setupQuantity(quantity)
{
    $('input#quantity').val(quantity);
}

/** 
 * select item into the cart
 * @param itemName string product identifer to be added
 */
function selectItem(itemName)
{
    $('input#item').val(itemName);

    addItemToCart(itemName, parseInt($('input#quantity').val()));

}

function adjustModalMaxHeightAndPosition(el) {
    $(el).each(function () {
        if ($(this).hasClass('in') === false) {
            $(this).show();
        }
        var contentHeight = $(window).height() - 60;
        var headerHeight = $(this).find('.modal-header').outerHeight() || 2;
        var footerHeight = $(this).find('.modal-footer').outerHeight() || 2;

        $(this).find('.modal-content').css({
            'max-height' : function () {
                return contentHeight;
            }
        });

        $(this).find('.modal-body').css({
            'max-height' : function () {
                return contentHeight - (headerHeight + footerHeight);
            }
        });

        $(this).find('.modal-dialog').addClass('modal-dialog-center').css({
            'margin-top' : function () {
                return -($(this).outerHeight() / 2);
            },
            'margin-left' : function () {
                return -($(this).outerWidth() / 2);
            }
        });
        if ($(this).hasClass('in') === false) {
            $(this).hide();
        }
    });
}