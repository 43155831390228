/**
 * function gets URL parameter form the query string
 * @param strParamName string parameter name 
 * @return string unescaped parameter value
 */
function getURLParam(strParamName)
{
    strReturn = '';
    strHref = window.location.href;
    if (strHref.indexOf('?') > -1) {
        strQueryString = strHref.substr(strHref.indexOf('?'));
        aQueryString = strQueryString.split('&');
        for (iParam = 0; iParam < aQueryString.length; iParam++) {
            if (aQueryString[iParam].toLowerCase().indexOf(strParamName.toLowerCase() + '=') > -1) {
                aParam = aQueryString[iParam].split('=');
                strReturn = aParam[1];
                return unescape(strReturn);
                break;
            }
        }
    }

    return null;
}

